import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/:slug',
    name: 'page',
    component: () => import('../views/Page.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,
  scrollBehavior: function () {
    return { x: 0, y: 0 }
  }
})

export default router
