<template>
    <li v-if="submenu.length === 0 || submenu.length > 0" class="nav-menu-item"><router-link :to="link">{{ title }}</router-link></li>
    <li v-else class="nav-menu-item" :class="dropdown && 'open'" @mouseover="dropdown = true" @mouseleave="dropdown = false">
        <a href="#" class="nav-menu-dropdown-title">{{ title }} &#x25BE;</a>
        <ul class="nav-menu-dropdown" @mouseover="dropdown = true" @mouseleave="dropdown = false" :class="dropdown ? 'open' : ''">
            <li v-for="(m,i) in submenu" :key="i" class="nav-menu-dropdown-item"><router-link :to="getUrl(m)">{{ m.title }}</router-link></li>
        </ul>
    </li>
</template>

<script>
    export default {
        name: "MenuItem",
        props: {
            link: String,
            title: String,
            submenu: {
                type: Array,
                default: function() { return []; }
            }
        },
        data() {
            return {
                dropdown: false
            }
        },
        methods: {
            getUrl(item) {
                return item.url.replace(/^.*\/\/[^/]+/, '')
            }
        }
    }
</script>

<style lang="scss">
    .nav-menu {

        &-item{
          display: flex;
          align-items: center;
          transition: all .4s ease;

          &:last-child {
            position: relative;
            padding: 8px 32px;
            border-radius: 40px;
            background-color: #1428A0;

            &>a {
              color: #fff;
            }
          }
            &>a{
                position: relative;
                display: inline-flex;
                @extend %navigation-menu;
                color: $black;
                cursor: pointer;
                width: 100%;

                &.disabled{
                    background: $gray-100;
                    pointer-events: none;
                    color: $gray-500;
                }

                &.nav-menu-dropdown-title{
                  cursor: default;
                  transition: all .4s ease;

                    &::after{
                        z-index: 1;
                        content: " ";
                        position: absolute;
                        bottom: 0;
                        border-width: 0 16px 4px 16px;
                        border-color: transparent transparent $primary transparent;
                    }

                  &:hover {
                    text-decoration: none;
                  }
                }

                &.router-link-exact-active{
                  text-decoration: underline;
                }
            }

            &>a{ text-decoration: none; }
            &>a:hover{ text-decoration: underline; }

            @include media-breakpoint-up(lg){
                // Showing dropdown menu
                .nav-menu-dropdown-title:hover + .nav-menu-dropdown,
                //.nav-menu-dropdown-title:focus + .nav-menu-dropdown
                {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

          &.open {
            background: transparent;

            @include media-breakpoint-down(lg){
              display: flex;
              flex-direction: column;
            }

            & > a {
              color: black;
            }
          }
        }

        &-dropdown{
            display: none;
            position: relative;
            @include media-breakpoint-up(lg){
              position: absolute;
              top: 10px;
              //transform: translateY(-50%);
              opacity: 0;
              z-index: -1;
            }
            &.open{
              display: flex;
              @include media-breakpoint-up(lg){
                //transform: translateY(0);
                top: 55px;
                opacity: 1;
                z-index: 1;
              }
            }
            flex-direction: column;
            list-style-type: none;
            background: $white;
            margin: 0;
            padding: 0;
            border-left: 1px solid $gray-200;

            &-item{
                &>a{
                    position: relative;
                    display: inline-flex;
                    padding: 8px 16px 8px 16px;
                    @extend %navigation-menu;
                    color: $gray-800;
                    cursor: pointer;
                    width: 100%;

                    &.disabled{
                        background: $gray-100;
                        pointer-events: none;
                        color: $gray-500;
                    }

                    &.router-link-active, &.router-link-exact-active{ color: $primary; }

                    @include media-breakpoint-up(lg){
                        padding: 16px 24px;
                        color: $black;

                        &:hover{
                            background: $primary;
                            color: $white;
                        }
                    }
                }

                &>a, &>a:hover{ text-decoration: none; }
            }

            @include media-breakpoint-up(lg){
                box-shadow: 0 8px 12px -4px rgba($black, 0.24);
                position: absolute;
                display: flex;
                border-left: none;
                opacity: 0;
                pointer-events: none;

                &:hover {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
      &-dropdown {
        border-radius: 21px;
        margin-left: -16px;
        transition: all .4s ease;
        background: $primary;

        //@include media-breakpoint-up(lg){
        //  margin-top: 180px;
        //}

        &-item {
          padding: 6px 24px;
          border-radius: 20px;
          background: $primary;
          color: $white;
          transition: all .4s ease;

          &:has(.router-link-active, .router-link-exact-active) {
            background: $white;
          }

          & a {
            color: $white;
            background: transparent;
          }

          &:hover {
            background: $white;
            color: $white;

            & a {
              background: transparent;
              color: black;
            }
          }

          @include media-breakpoint-up(lg){
            & a {
              color: $white;
              background: transparent;
            }

            &:hover {
              background: $white;
              color: $white;

              & a {
                text-decoration: underline;
                background: transparent;
                color: black;
              }
            }
          }

          & .router-link-active, &.router-link-exact-active {
            color: $black!important;
          }
        }
      }
    }
</style>
