<template>
    <div>
        <h5>{{ title }}</h5>
        <ul class="nav_menu_container">
            <li v-for="(item, i) in items" :key="i">
                <router-link :to="getUrl(item.link)">{{ item.link.title }}</router-link>
<!--                <a class="h6" :href="item.link.url" :target="item.link.target">{{ item.link.title }}</a>-->
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "NavMenu",
        props: {
            title: String,
            items: Array,
        },
      methods: {
        getUrl(item) {
          return item.url.replace(/^.*\/\/[^/]+/, '')
        }
      }
    }
</script>

<style scoped lang="scss">
    h5 {
      font-size: 1.125em;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 16px;
    }

    .nav_menu_container {
      display: flex;
      flex-direction: column;
      gap: 12px;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0;
            }

            a {
              color: #393947;
              font-size: .95em;
              font-style: normal;
              font-weight: 500;
            }
        }
    }
</style>
