<template>
    <footer class="footer">
      <img class="shape-1" src="./../assets/media/icons/shape-footer-top.svg" alt="Shape image" />
      <img class="shape-3" src="./../assets/media/icons/shape-footer-bottom.svg" alt="Shape image" />
      <b-container>
            <b-row align-h="center">
              <b-col class="d-flex w-100 footer__wrapper" cols="12" xl="10">
                <img class="shape-2" src="./../assets/media/icons/shape-footer-corner.svg" alt="Shape image" />
                <b-col class="order-3 order-md-0 mt-5 mt-md-0 justify-content-between d-flex flex-column" cols="12" md="4">
                  <div class="footer__logos d-flex flex-column">
                    <img class="logo logo_samsung" src="./../assets/media/logo/samsung-black.svg" alt="Samsung logo" />
<!--                    <img class="logo logo_sft" src="./../assets/media/logo/sft.svg" alt="SFT logo" />-->
<!--                    <img class="logo logo_ep" src="./../assets/media/logo/ep-logo-black.svg" alt="EP logo" />-->
                  </div>
                  <div class="d-flex footer__social">
                      <a v-if="social_media && social_media.facebook" :href="social_media.facebook">
                        <img class="logo_ep" src="./../assets/media/icons/facebook.svg" alt="Facebook logo" />
                      </a>
                      <a v-if="social_media && social_media.instagram" :href="social_media.instagram">
                        <img v-if="social_media.instagram" class="logo_ep" src="./../assets/media/icons/instagram.svg" alt="Instagram logo" />
                      </a>
                  </div>
                </b-col>
                <b-col class="" cols="12" md="3" v-if="nav_menus">
                  <b-row>
                    <b-col cols="12">
                      <nav-menu :title="nav_menus[0].title" :items="nav_menus[0].links"></nav-menu>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="4" class="mt-5 mt-md-0 d-flex flex-column justify-content-center">
                  <h4 class="footer__text">{{footer_text}}</h4>
                  <a class="footer__email" :href="`mailto:${contact}`">{{contact}}</a>
                </b-col>
              </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
    import NavMenu from "./footer/NavMenu";

    export default {
        name: "PageFooter",
        components: {NavMenu},
        computed: {
            contact() {
                return this.$store.getters.setting('contact_email')
            },
            footer_text() {
              return this.$store.getters.setting('text_footer')
            },
            nav_menus() {
              return this.$store.getters.setting('nav')
            },
            social_media() {
              return this.$store.getters.setting('social_media') || {};
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
      position: relative;
      padding-bottom: 140px;
      padding-top: 70px;
      background: #F2F8FF;
      color: #393947;

      @include media-breakpoint-down(sm){
        padding: 70px 12px;
      }

      &__wrapper {
        position: relative;
        padding: 64px 96px!important;
        background-color: #fff;
        border-radius: 20px;
        overflow: hidden;
        justify-content: space-between;
        z-index: 1;

        @include media-breakpoint-down(lg){
          padding: 64px 48px!important;
        }

        @include media-breakpoint-down(md){
          padding: 32px 16px!important;
          flex-wrap: wrap;
        }
      }

        .logo{ max-width: 160px; }

      &__text {
        color: #393947;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 16px;
      }

      &__email {
        font-size: 1em;
        font-style: normal;
        font-weight: 500;
        text-decoration-line: underline;
        color: #393947;
      }

      &__logos {
        gap: 32px;
      }

      &__social {
        gap: 24px;
        margin-top: 64px;

        @include media-breakpoint-down(md){
          margin-top: 32px;
        }
      }
    }

    .contrast .footer{
        background: $black;

        .copyright{
            &, *{ color: $blue-100; }
        }
    }

    .shape-1 {
      position: absolute;
      right: 0;
      top: -22%;
      z-index: 1;

      @include media-breakpoint-down(sm){
        top: -10%;
        width: 35%;
      }
    }

    .shape-2 {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;

      @include media-breakpoint-down(sm){
        width: 50%;
      }
    }

    .shape-3 {
      position: absolute;
      left: 0;
      bottom: 0;

      @include media-breakpoint-down(sm){
        width: 50%;
      }
    }
</style>
