<template>
  <div :class="{'contrast' : $store.state.contrast}" :style="'font-size:' + $store.state.fontsize + 'px'">
    <navigation-bar v-if="!isFormPage" />
    <main>
      <router-view/>
    </main>
    <page-footer v-if="!isFormPage" />
  </div>
</template>

<script>
  import NavigationBar from "./components/NavigationBar";
  import PageFooter from "./components/PageFooter";

  export default {
    components: {NavigationBar, PageFooter},
    computed: {
      isFormPage() {
        return this.$route.path === '/formularz/';
      }
    },
    mounted() {
      this.$store.dispatch('init')
    }
  }
</script>

<style lang="scss">
  @import "assets/scss/app";
</style>
