<template>
    <nav :class="{
  'scroll-top': scrollTop > 0 && scrollPosition.actual !== 0,
  'scroll-bottom': scrollPosition.actual !== 0 && scrollPosition.actual !== null,
  'open': collapse,
}" class="navigation-bar">
        <b-container>
            <b-row align-h="center" align-v="center">
                <b-col order="2" order-lg="3" class="d-flex d-xl-none justify-content-end">
                    <button class="hamburger-menu" @click="collapse = !collapse" :class="collapse ? 'open' : ''">
                        <span></span>
                    </button>
                </b-col>
                <b-col cols="12" xl="10" lg order="3" order-lg="2" class="collapse-menu">
                    <div class="d-xl-flex align-items-start align-items-lg-end flex-column pt-3 pb-4" :class="collapse ? 'd-flex' : 'd-none'">
                        <div class="options-menu justify-content-center mb-3">
                            <div class="resize">
                                <button type="button" name="decrease zoom" class="resize-button decrease" @click="decreaseFont"><span>-</span>Aa</button>
                                <span class="resize-value" @click="resetFont" title="Kliknij by zresetować">{{ zoomValue }}<span>%</span></span>
                                <button type="button" name="increase zoom" class="resize-button increase" @click="increaseFont">Aa<span>+</span></button>
                            </div>
                          <button type="button" name="switch contrast" class="ml-4 contrast" @click="switchContrast"><icon icon="eye" /></button>
                        </div>
                        <nav-menu></nav-menu>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </nav>
</template>

<script>
    import Icon from "./utility/Icon";
    import NavMenu from "./menu/NavMenu";

    export default {
        components: {
            NavMenu,
            Icon,
        },

        data: function(){
            return{
                collapse: false,
                scrollPosition: {
                    actual: null,
                    before: null,
                },
            }
        },

        methods: {
            updateScroll() {
                this.scrollPosition.before = this.scrollPosition.actual
                this.scrollPosition.actual = window.scrollY
            },
            switchContrast() {
                this.$store.commit('SWITCH_CONTRAST')
            },
            decreaseFont() {
                this.$store.commit('DEC_FONT');
            },
            increaseFont() {
                this.$store.commit('INC_FONT');
            },
            resetFont() {
                this.$store.commit('RESET_FONT');
            }
        },

        computed: {
            scrollTop: function() { return this.scrollPosition.before - this.scrollPosition.actual },
            zoomValue: function() { return this.$store.state.fontsize / 0.16 }
        },

        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },

        watch: {
            '$route' () {
                this.collapse = false
            }
        },

        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        },
    }
</script>

<style lang="scss">
    nav.navigation-bar{
        //background: #F2F8FF;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        z-index: 99;
        //box-shadow: inset 0 -1px 0 0 $gray-100;
        transition: 0.5s ease;

      &.open {
        background: $main-bg;
      }

        @include media-breakpoint-up(xl){
            background: transparent;
            position: fixed;
            padding-top: 0;
            padding-bottom: 0;

            &.scroll-top{
              background: $main-bg;
            }

            &.scroll-bottom{
                $offset: 48px;
                top: -$offset;
                background: $main-bg;

                .logo{
                    transform-origin: bottom;
                    transform: scale(.9) translateY(16px);
                     margin-bottom: 4px;
                }
            }
        }

        .logo{
            width: auto;
            max-height: 50px;
            transition: 0.5s ease;

            @include media-breakpoint-up(xl){
                max-height: 70px;
            }
        }

        .collapse-menu{
            @include media-breakpoint-down(md){
                max-height: 72.5vh;
                overflow-y: auto;
            }
        }

        .hamburger-menu{
            $size: 24px; // Size of squere
            $height: 3px; // Lines thickness
            $inactiveColor: $black;
            $activeColor: $primary;
            $transition: 0.24s ease;
            $center: calc( (#{$size} - #{$height}) / 2 );

            position: relative;
            display: flex;
            width: $size;
            height: $size;
            border: none;
            background: transparent;
            padding: 0;

            &:focus{ outline: none; }

            span{
                position: absolute;
                top: $center;

                &::before, &::after{
                    position: absolute;
                    content: " ";
                }

                &, &::before, &::after{
                    height: $height;
                    width: 100%;
                    left: 0;
                    background: $inactiveColor;
                    transition: $transition;
                    border-radius: 4px;
                }

                &::before{
                    top: calc( #{$center} * -0.65 );
                }

                &::after{
                    bottom: calc( #{$center} * -0.75 );
                }
            }

            &.open{
                span{
                    background: transparent;

                    &::before, &::after{
                        top: 0;
                        background: $activeColor;
                    }

                    &::before{ transform: rotate(45deg); }
                    &::after{ transform: rotate(-45deg); }
                }
            }
        }

        .options-menu{
          @include media-breakpoint-down(xs){
            width: 100%;
          }
            // delete this when will be working
            .lang img{
                pointer-events: none;
                opacity: 0.2;
            }
            //

            display: flex;
            flex-direction: row;
            align-items: center;

            //&>*{ margin-left: 24px; }

            //@include media-breakpoint-up(lg){
            //    &>*{ margin-left: 48px; }
            //}

          .zoom {
            font-size: .9em;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #393947;
          }

            .contrast{
                background: none;
                border: 1px solid $black;
                border-radius: 4px;
                padding: 4px;
                display: flex;

                .icon{
                    width: 14px;
                    // height: 20px;
                }

                &:hover{
                    background: $black;

                    svg path{ fill: $white; }
                }
            }

            .resize{
                display: flex;
                flex-direction: row;
                align-items: center;

                &-value{
                    display: inline-flex;
                    justify-content: center;
                    align-items: flex-end;
                    color: $black;
                    @extend %navigation-resize-value;
                    //margin: 0 4px;
                    min-width: 54px;
                    text-align: center;
                    //padding: 9px 12px;
                    border-radius: 2px;
                    cursor: pointer;

                    font-size: 1.125em;
                    font-weight: 500;
                    line-height: 25px;
                    letter-spacing: 0;

                    &>span{
                        margin-left: 1px;
                        margin-bottom: 1px;
                    }
                }

                &-button{
                    @extend %navigation-resize-button;

                    font-size: 1.125em;
                    font-weight: 500;
                    line-height: 30px;
                    letter-spacing: 0em;

                    background: none;
                    border: none;
                    //padding: 6px;
                    position: relative;

                    span {
                      position: absolute;
                      font-size: 1.4em;
                    }

                    &:first-child span {
                      top: 0;
                      transform: translateX(-100%);
                    }
                }


            }

            .lang{
                position: relative;
                display: flex;
                height: 20px;
            }
        }
    }

    // Because of navbar height on <992px (lg)
    nav.navigation-bar + main{
        //margin-top: 72px;

        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }

    // Contrast
    .contrast nav.navigation-bar{
        box-shadow: inset 0 -1px 0 0 $gray-500;

        .options-menu{
            .contrast{
                background: $black;

                svg path{ fill: $white; }
            }
        }
    }
</style>
